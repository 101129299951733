import { createSelector } from 'reselect';
import includes from 'lodash/includes';

const selectBasicInfo = (state) => state.basicInfo;

export const makeSelectBasicInfoRequest = () =>
  createSelector(selectBasicInfo, (basicInfoState) =>
    basicInfoState.filter((value, key) =>
      includes(
        [
          'loading',
          'error',
          'success',
          'message',
          'isSubmitting',
          'isSubmitted',
        ],
        key
      )
    )
  );

export const makeSelectBasicInfoData = () =>
  createSelector(selectBasicInfo, (basicInfoState) =>
    basicInfoState.get('basicInfoData')
  );
export const makeSelectMyInfoProfile = () =>
  createSelector(selectBasicInfo, (basicInfoState) =>
    basicInfoState.get('myInfoProfileData')
  );

export const makeSelectIsInitCompleted = () =>
  createSelector(selectBasicInfo, (basicInfoState) =>
    basicInfoState.get('isInitCompleted')
  );

export const makeSelectBlackStatus = () =>
  createSelector(selectBasicInfo, (basicInfoState) =>
    basicInfoState.get('isBlack')
  );

export const makeSelectAgeStatus = () =>
  createSelector(selectBasicInfo, (basicInfoState) =>
    basicInfoState.get('isAgeInvalid')
  )